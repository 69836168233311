@font-face {
  font-family: 'Noto Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans Regular'), url('fonts/NotoSans/NotoSans-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Noto Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans Italic'), url('fonts/NotoSans/NotoSans-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Noto Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans Bold'), url('fonts/NotoSans/NotoSans-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Noto Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Noto Sans Bold Italic'), url('fonts/NotoSans/NotoSans-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  src: local('SF Pro Display'), url('fonts/SfProDisplay/SFPRODISPLAYREGULAR.OTF') format('OTF');
}

.App {
  display: flex;
  flex-direction: column;
  background: #131620;
  overflow-y: scroll;
  height: 100vh; //for no scroll
  position: relative;
}
.web-version {
  display: none;
}
@media (min-width: 1024px) {
  .mob-version {
    display: none;
  }
  .web-version {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 82px 24px 0 24px;
    gap: 85px;
    &_content {
      display: flex;
      gap: 57px;
      align-items: center;
      &__right {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &___title {
          color: #FFF;
          font-family: "Noto Sans Bold";
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 48px;
          margin-bottom: 16px;
        }
        &___info {
          color: #FFF;
          font-family: "Noto Sans Regular";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  &_right {
    display: flex;
    gap: 16px;
    align-items: center;
    &__connect-btn {
      display: flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 24px;
      background: linear-gradient(90deg, #DC143C 0%, #FF0080 100%);
      box-shadow: 0px 0px 17px 0px rgba(153, 153, 153, 0.90) inset, 24px 12px 24px 0px rgba(237, 100, 100, 0.45) inset;
      cursor: pointer;
    }
  }
}

.wallet-address-btn {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 24px;
  border: 1px solid #DC143C;
  cursor: pointer;
  span {
    color: #FFF;
    font-family: "Noto Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.language-select {
  &.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
    background: inherit;
    outline: unset!important;
    box-shadow: unset!important;
  }

  &.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: unset;
  }

  &.ant-select-single .ant-select-selector {
    color: #F2F2F2;
    text-overflow: ellipsis;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.ant-select .ant-select-arrow {
    color: #F2F2F2;
    transition: transform 0.3s ease;
  }

  &.ant-select-open .ant-select-arrow {
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #F2F2F2;
  }

  &.ant-select-dropdown {
    border-radius: 16px;
    background: #1D1F2E;
    width: 194px!important;
  }

  &.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #F2F2F2;
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: inherit;
  }
}
.flag-content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #F2F2F2;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 4px;
}


.auth-layout {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  align-items: center;
  gap: 16px;
  margin-bottom: 85px;
}

.footer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  //padding: 12px 16px;
  padding: 12px 0px; // if min-width
  background: #131620;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 72px; // if min width
  width: 100%;  // if min width
  gap: 6px;
  //background: red;

  &_name {
    color: #B5B5B5;
    font-family: "Noto Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
  }
}

.is-active {
  color: #FFFFFF
}

.jackpot-card {
  display: flex;
  flex-direction: column;
  background: url("./assets/backgrounds/jackpot-card-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #9EA830;
  width: 100%;
  gap: 24px;
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 168px;
    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      margin-left: 16px;
      z-index: 1;
      &___title {
        color: #FFF;
        font-family: "Noto Sans Bold";
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        text-transform: uppercase;
      }
      &___subtitle {
        color: #B5B5B5;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 4px;
      }
      &___ticket-count {
        display: flex;
        gap: 4px;
        align-items: flex-end;
        &____accessible-count {
          color: #FFD700;
          font-family: "Noto Sans Bold";
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 39px;
        }
        &____total-count {
          color: #B5B5B5;
          font-family: "Noto Sans Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
    img {
      position: absolute;
      right: 0;
      top:0
    }
  }
  &_content {
    color: #DDD;
    text-align: center;
    font-family: "Noto Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 0 16px;
  }
  &_btn {
    padding: 8px 16px 31px 16px;
  }
}
.main-button {
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 16px 0;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: linear-gradient(90deg, #DC143C 0%, #FF0080 100%);
  box-shadow: 0px 0px 17px 0px rgba(153, 153, 153, 0.90) inset, 24px 12px 24px 0px rgba(237, 100, 100, 0.45) inset;
  cursor: pointer;
  span {
    color: #FFF;
    font-family: "Noto Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.mega-jackpot-card {
  display: flex;
  flex-direction: column;
  background: url("./assets/backgrounds/mega-jackpot-card-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  border: 1px solid #9EA830;
  width: 100%;
  gap: 16px;
  padding-top: 16px;
  &_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    &__title {
      color: #FFF;
      text-align: center;
      font-family: "Noto Sans Bold";
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
}
.datetime-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 294px;
  width: 100%;
  span {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 50%;
  }
}
.mini-datetime-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  width: 100%;
  span {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: -18px;
  }
}

.datetime-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //gap: 8px;
  width: 100%;
}

.datetime-number {
  color: #FFF;
  text-align: center;
  font-family: "Noto Sans Bold";
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
}
.mini-datetime-number {
  color: #FFF;
  text-align: center;
  font-family: "Noto Sans Bold";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
}
.datetime-text {
  color: #FFE80D;
  text-align: center;
  font-family: "Noto Sans Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}


.info-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}
.success-header {
  color: #F2F2F2;
  font-family: "Noto Sans Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.96px;
  margin-top: 16px
}
.success-text {
  color: #C2C2C2;
  text-align: center;
  font-family: "Noto Sans Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-top: 8px;
}
.success-button {
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  border: unset;

  border-radius: 40px;
  background: var(--Button-Gradient, linear-gradient(90deg, #DC143C 0%, #FF0080 100%));
  box-shadow: 0px 0px 17px 0px rgba(153, 153, 153, 0.90) inset, 24px 12px 24px 0px rgba(237, 100, 100, 0.45) inset;
  margin-top: 24px;
  color: #FFF;
  font-family: "Noto Sans Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.info-modal {
  &.ant-modal .ant-modal-content {
    border-radius: 16px;
    background: #1D1F2E;
  }
}

.jackpot {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;

  &_title {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 8px;

    span {
      color: #FFF;
      font-family: "Noto Sans Bold";
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    background: url("./assets/backgrounds/jackpot-mini-card.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    border: 1px solid #9EA830;
    width: 100%;
    height: 145px;
    position: relative;
    overflow: hidden;
    &__logo {
      position: absolute;
      right: -17px;
      top:0;
      width: 148px;
      height: 145px;
    }
    &__price {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-left: 16px;
      z-index: 1;
      &___unit-price {
        color: #FFE80D;
        font-family: "Noto Sans Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 4px;
      }
      &___text {
        color: #DDD;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &__tickets-count {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-left: 16px;
      z-index: 1;
      &___item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &____title{
          color: #DDD;
          text-align: center;
          font-family: "Noto Sans Regular";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        &____count{
          color: #FFD700;
          text-align: center;
          font-family: "Noto Sans Bold";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
}

.buy-ticket-block {
  display: flex;
  width: 100%;
  padding: 16px 0;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: #1D1F2E;
  &_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 0 16px;
    &__title {
      color: #DDD;
      font-family: "Noto Sans Bold";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &__subtitle {
      color: #B5B5B5;
      font-family: "Noto Sans Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &_btn {
    padding: 8px 16px 0 16px;
  }
}
.ticket-count-input {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #131620;
  //background: #9EA830;
  margin: 0 16px;
  &_count {
    width: 100%;
    text-align: center;
    height: 34px;
    background: #131620;
    color: #FFF;
    font-family: "Noto Sans Regular";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.12px;
    border: unset;
    &:focus-visible {
      outline: unset;
      cursor: none;
    }
  }
}

.choose-number-block {
  display: flex;
  width: 100%;
  padding: 16px 0;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #1D1F2E;
  margin-bottom: 85px;
  &_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    gap: 8px;
   &__search-ticket-number {
     .ant-input::placeholder {
       color: #B5B5B5;
       font-family: "Noto Sans Regular";
       font-size: 10px;
       font-style: normal;
       font-weight: 500;
       line-height: 18px;
     }
     &.ant-input-affix-wrapper {
       display: flex;
       padding: 8px;
       //gap: 4px;
       align-items: center;
       border-radius: 4px;
       background: #131620;
       width: auto;
       height: 36px;
       color: #FFF;
       font-family: "Noto Sans Bold";
       font-size: 10px;
       font-style: normal;
       font-weight: 500;
       line-height: 18px;
       border: unset;
     }
     &.ant-input-outlined:focus-within {
       box-shadow: unset
     }
   }
    &__switch {
      display: flex;
      align-items: center;
      gap: 7px;
      span {
        color: #B5B5B5;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap;
      }
    }
  }
  &_tickets {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    gap: 8px;
    flex-wrap: wrap;
    &__item {
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;
      &___number {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-family: "Noto Sans Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: #000;
      }
    }
    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 8px;
      width: 100%;
      padding-bottom: 16px;
      span {
        color: #B5B5B5;
        text-align: center;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        max-width: 64%;
      }
    }
  }
}

.disabled {
  color: #5C5C5C;
  text-decoration-line: line-through;
}
.switcher {
  &.ant-switch:hover:not(.ant-switch-disabled){
    border: 1px solid #B5B5B5;
    background: #1D1F2E;
  }
  &.ant-switch.ant-switch-checked {
    background: linear-gradient(90deg, #B92FF6 -21.16%, #181BF4 114.42%)!important;
    border: unset!important;
  }
}

.mega-jackpot {
  &_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    background: url("./assets/backgrounds/mega-jackpot-mini-card-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    border: 1px solid #9EA830;
    width: 100%;
    height: 145px;
    position: relative;
    overflow: hidden;
    &__logo {
      position: absolute;
      right: -40px;
      width: 148px;
      height: 145px;
    }
    &__price {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-left: 16px;
      z-index: 1;
      &___unit-price {
        color: #FFE80D;
        font-family: "Noto Sans Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 4px;
      }
      &___text {
        color: #DDD;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    &__countdown{
      display: flex;
      align-items: flex-start;
      margin-left: 16px;
      z-index: 1;

    }
  }
}

.friends {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  padding: 0 16px 32px 16px;
  margin-bottom: 85px;
  &_header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: url("./assets/backgrounds/ref-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: center;
      border-radius: 8px;
      border: 1px solid #9EA830;
      width: 100%;
      height: 128px;
      position: relative;
      overflow: hidden;
      &___title {
        margin-left: 16px;
        width: 50%;
        color: #FFF;
        font-family: "Noto Sans Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        z-index: 1;
      }
      &___logo {
        position: absolute;
        right: 0px;
        height: 128px;
      }
    }
    &__content {
      color: #FFF;
      text-align: center;
      font-family: "Noto Sans Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.35px;
    }
  }
  &_identifier {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__title {
      color: #FFD700;
      font-family: "Noto Sans Bold";
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &__content {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 16px;
      background: #1D1F2E;
      span {
        color: #DDD;
        font-family: "Noto Sans Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.08px;
      }
    }
  }
  &_friends-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__title {
      color: #FFD700;
      font-family: "Noto Sans Bold";
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &__item {
      display: flex;
      height: 59px;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      background: #1D1F2E;
      backdrop-filter: blur(32px);
      &___info {
        display: flex;
        gap: 8px;
        align-items: center;
        span {
          color: #FFF;
          font-family: "Noto Sans Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      &___count{
        color: #B5B5B5;
        font-family: "Noto Sans Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.share-modal {
  &_content {
    color: #C2C2C2;
    font-family: "Noto Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    margin-bottom: 32px;
  }
  &_icons-group {
    position: absolute;
    right: 0;
    bottom: 32px;
    width: 97%;
    display: flex;
    gap: 32px;
    align-items: center;
    overflow: auto;
    white-space: nowrap;
    &__item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      cursor: pointer;
      span {
        color: #E7E7E7;
        font-family: "Noto Sans Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

  }
  .ant-modal-content {
    position: relative;
    display: flex;
    padding: 28px 0px 32px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    background: #1D1F2E;
    height: 280px;
  }
  &.ant-modal .ant-modal-close-x {
    color: #B5B5B5;
    margin-top: 24px;
  }
  &.ant-modal .ant-modal-header {
    background: inherit;
  }
  &.ant-modal .ant-modal-title {
    color: #F2F2F2;
    font-family: "Noto Sans Bold";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.96px;
  }
}
.how-to-play {
  padding: 0 16px;
  margin-top: 32px;
  margin-bottom: 100px;
}
.video-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px 16px;
  justify-content: space-between;
}
.video-block-header {
  color: #FFD700;
  font-family: "Noto Sans Bold";
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 24px;
}

.video-item {
  display: flex;
  flex-direction: column;
  padding: 16px 8px 16px 16px;
  justify-content: space-between;
  width: 140px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: #272D41;
  background-blend-mode: overlay;
  backdrop-filter: blur(30px);
  &_top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  &_bottom {
    color: #FFF;
    font-family: "Noto Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.35px;
  }
}
.video-item-open {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: #272D41;
  background-blend-mode: overlay;
  backdrop-filter: blur(30px);
  gap: 8px;
  transition: .8s ease-in-out;
  overflow: hidden;
}
.video-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &_left {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    span {
      color: #FFF;
      font-family: "Noto Sans Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.35px;
      //width: 70%;
    }
  }
}

video {
  width: 100%;
  border-radius: 8px;
  right: 0;
  bottom: 0;
}

.profile {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 0 16px;
  &_header {
    color: #FFD700;
    font-family: "Noto Sans Bold";
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
  }
  &_switch-btn-group {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    background: #1D1F2E;
    &__btn {
      display: flex;
      width: 100%;
      padding: 8px 0;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      &___content {
        color: #B5B5B5;
        font-family: "Noto Sans Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
        background: #1D1F2E;
      }
    }
  }
}
.is-active-btn {
  background: #9F2CF6;
  color: #FFF;
  transition: all .3s ease;
}

//Ticket Collapse

.active-tickets {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &_header {
    color: #FFF;
    font-family: "Noto Sans Bold";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.35px;
  }
}

.ticket-collapse {
  &.ant-collapse-borderless {
    border-radius: 8px;
  }
  &.ant-collapse {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    background: #272D41;
    background-blend-mode: overlay;
    backdrop-filter: blur(30px);
  }
  .ant-collapse-item {
    width: 100%;
    border-radius: 8px!important;
    &.ant-collapse-item-active {
      background: inherit;
      border: solid 1px transparent; /* !importanté */
      &:before {
        content: '';
        position: absolute;
        top: -1px; right: 1px; bottom: 1px; left: 1px;
        z-index: -1;
        margin: -1px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to left, #181BF4, #B92FF6);
      }
    }
    .ant-collapse-content {
      //border-top: 1px solid;
      //border-image: linear-gradient(to right,#B92FF6, #181BF4) 1 0 0 0;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .ant-collapse-item-active img {
    transform: rotate(-180deg);
  }
  &.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    margin-top: 6px
  }
  &.ticket-collapse.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 8px 0;
  }
}

.ticket-collapse-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &_left {
    display: flex;
    gap: 8px;
    img {
      transform: unset!important;
    }
    &__ticket-number {
      color: #FFF;
      font-family: "Noto Sans Regular";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.35px;
    }
  }
  &_right-active {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    background: #C5F5D9;
    border-radius: 6px;
    color:  #0C7436;
    text-align: center;
    font-family: "Noto Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &_right-not-active {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    background: #FFD6CC;
    border-radius: 6px;
    color:  #991F01;
    text-align: center;
    font-family: "Noto Sans Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.ticket-collapse-item-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  //padding: 0 16px;
  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      color: #B5B5B5;
      font-family: "Noto Sans Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.35px;
    }
    &__right {
      color: #FFF;
      font-family: "Noto Sans Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.35px;
    }
  }
}

//End Ticket Collapse

.more {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  margin-bottom: 100px;
  padding: 0 16px;
  &_logo {
    text-align: center;
    width: 100%;
    margin-top: 40px;
  }
}
.info-item {
  display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #1D1F2E;
  &_left {
    display: flex;
    gap: 8px;
    align-items: center;
    span {
      color: #FFF;
      font-family: "Noto Sans Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.08px;
    }
  }
}
.custom-line {
  height: 0.5px;
  background-color: #5B5B5B;
  margin-top: 8px;
  margin-bottom: 24px;

}

.rules {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 16px;
  margin-top: 32px;
  margin-bottom: 100px;
}

.rule-collapse {
  .ant-collapse-header {
    padding: 16px!important;
    align-items: center!important;
    color: #FFF!important;
    font-family: "Noto Sans Regular!important;";
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 18px!important;
    letter-spacing: 0.35px!important;
  }
  &.ant-collapse {
    width: 100%!important;
    display: flex!important;
    border-radius: 16px!important;
    //border: 1px solid #000!important;
    background: #1D1F2E!important;
  }
  .ant-collapse-item {
    width: 100%;
  }
  .ant-collapse-content {
    border-top: 0px!important;
  }
  .ant-collapse-item-active img {
    transform: rotate(-180deg);
  }
}

.rule-collapse.ant-collapse .ant-collapse-content {
  width: 100%!important;
  border-radius: 0 0 16px 16px!important;
  background: #1D1F2E!important;
  color: #DDD;
  font-family: "Noto Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.35px;
}
.collapse-item {
  display: flex;
  align-items: center;
  gap: 12px;
}
.collapse-item-number {
  display: flex;
  padding: 0px 7px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #56AE5B;
  color: #FFF;
  font-family: "Noto Sans Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.collapse-item-text {
  color: #FFF;
  font-family: "Noto Sans Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.35px;
}

.faq {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  padding: 0 16px;
  margin-top: 32px;
  margin-bottom: 100px;
}
.faq-collapse-item-text {
  color: #FFF;
  font-family: "Noto Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.35px;
}
.faq-collapse-item-content {
  color: #DDD;
  font-family: "Noto Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.35px;
}
.faq-collapse.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px 0;
}
.faq-collapse {
  &.ant-collapse-borderless {
    border-radius: 8px;
    //border: 1px solid #272D41;
  }
  &.ant-collapse {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    background: #272D41;
    background-blend-mode: overlay;
    backdrop-filter: blur(30px);
  }
  .ant-collapse-item {
    width: 100%;
    border-radius: 8px!important;
    &.ant-collapse-item-active {
      background: inherit;
      border: solid 1px transparent; /* !importanté */
      &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -1px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(to left, #181BF4, #B92FF6);
      }
      .ant-collapse-content {
        border-top: 1px solid;
        border-image: linear-gradient(to right,#B92FF6, #181BF4) 1 0 0 0;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
  .ant-collapse-item-active img {
    transform: rotate(-180deg);
  }
}
.social {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 48px;
  margin-bottom: 100px;
  padding: 0 16px;
}

.global-component {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;
    margin-top: 32px;
    margin-bottom: 100px;
  &_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    &__title {
      color: #E7E7E7;
      font-family: "Noto Sans Bold";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    &__content {
      color: #B6B6B6;
      font-family: "Noto Sans Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}